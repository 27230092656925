.app-bar-header {
  .display-flex {
    display: flex;
  }
  .wrapper {
    flex: "3 0 auto";
  }
  .notification-section {
    display: flex;
    flex: 1 0 auto;
    justify-content: flex-end;
    align-items: center;
  }
  .MuiBox-root {
    .MuiBadge-badge {
      color: #fff;
    }
    .MuiCardHeader-root {
      padding: 0;
      .MuiCardHeader-avatar {
        margin-right: 10px;
      }
    }
  }
}
.headerIconWrapper {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 30%;
  height: 80px;
  border-radius: 30px;
}
.headerIconContainer {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-end;
  justify-content: center;
  gap: 8px;
  padding-bottom: 16px;
}
.iconsWrapper {
  display: flex;
  flex-direction: column;
}

.new-notifications {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 10px;
  z-index: 1;
  width: 10px;
  background-color: red;
  border-radius: 50%;
}