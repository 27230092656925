@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local('Muli Regular'), url('./fonts/Muli.ttf') format('woff');
}

@font-face {
  font-family: 'Muli Regular';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local('Muli Regular'), url('./fonts/Muli-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Muli Italic';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local('Muli Italic'), url('./fonts/Muli-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Muli ExtraLight';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local('Muli ExtraLight'),
    url('./fonts/Muli-ExtraLight.woff') format('woff');
}

@font-face {
  font-family: 'Muli ExtraLight Italic';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local('Muli ExtraLight Italic'),
    url('./fonts/Muli-ExtraLightItalic.woff') format('woff');
}

@font-face {
  font-family: 'Muli Light';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local('Muli Light'), url('./fonts/Muli-Light.woff') format('woff');
}

@font-face {
  font-family: 'Muli Light Italic';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local('Muli Light Italic'),
    url('./fonts/Muli-LightItalic.woff') format('woff');
}

@font-face {
  font-family: 'Muli SemiBold';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local('Muli SemiBold'), url('./fonts/Muli-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Muli SemiBold Italic';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local('Muli SemiBold Italic'),
    url('./fonts/Muli-SemiBoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Muli Bold';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local('Muli Bold'), url('./fonts/Muli-Bold.woff') format('woff');
  src: local('Muli Bold'), url('./fonts/Muli-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Muli Bold Italic';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local('Muli Bold Italic'),
    url('./fonts/Muli-BoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Muli ExtraBold';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local('Muli ExtraBold'),
    url('./fonts/Muli-ExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'Muli ExtraBold Italic';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local('Muli ExtraBold Italic'),
    url('./fonts/Muli-ExtraBoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Muli Black';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local('Muli Black'), url('./fonts/Muli-Black.woff') format('woff');
}

@font-face {
  font-family: 'Muli Black Italic';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local('Muli Black Italic'),
    url('./fonts/Muli-BlackItalic.woff') format('woff');
}

body {
  margin: 0;
  font-family: 'Muli Regular';
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Muli Regular';
}

.d-flex {
  display: flex;
}

.dir-col {
  flex-direction: column;
}

.dir-row {
  flex-direction: row;
}

::-ms-reveal {
  display: none;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
